<template>
  <Listbox v-model="selectedOption" :disabled="!isLoggedIn">
    <div class="filter-listing">
      <ListboxButton
        class="filter-listing-btn"
        :class="!isLoggedIn ? 'disabled' : ''"
      >
        <span
          class="absolute inset-y-0 left-0 flex items-center pl-2 pointer-events-none"
        >
          <Icon
            name="lock-filled"
            class="w-4 h-4 text-theme-secondary"
            aria-hidden="true"
            v-if="!isLoggedIn"
          />
          <Icon
            name="sort"
            class="w-4 h-4 text-theme-secondary"
            aria-hidden="true"
            v-else
          />
        </span>
        <span class="block truncate pl-5">{{ selectedOption.name }}</span>
        <span
          class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
        >
          <ChevronDownIcon
            class="w-5 h-5 text-theme-secondary"
            aria-hidden="true"
          />
        </span>
      </ListboxButton>

      <transition
        leave-active-class="transition duration-100 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ListboxOptions class="filter-listing-container">
          <ListboxOption
            v-slot="{ active, selected }"
            v-for="opt in optionsList"
            :key="opt.name"
            :value="opt"
            as="template"
          >
            <li :class="[selected || active ? 'active' : '']">
              <p
                :class="[
                  selected ? 'font-medium' : 'font-normal',
                  'block truncate',
                ]"
              >
                {{ opt.name }}
              </p>
              <span v-if="selected" class="check-mark">
                <CheckIcon aria-hidden="true" />
              </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>

<script>
import { onMounted, ref, watch } from "vue";
import Brands from "@/modules/Brands";
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from "@headlessui/vue";
import { CheckIcon, ChevronDownIcon } from "@heroicons/vue/solid";

const defaultOptions = [
  {
    name: "Newest Products",
    value: "latest",
    description:
      '"New Products" are products that are the newest in our catalog',
  },
  {
    name: "Most Popular",
    value: "popularity",
    description:
      '"Most Popular" are products that the most shops are purchasing',
  },
  {
    name: "Best Selling",
    value: "best-selling",
    description:
      '"Best Selling" are products with the highest quantity of sales',
  },
  {
    name: "Alphabetical Sort",
    value: "name",
    description: "Products sorted from A to Z",
  },
  {
    name: "Lowest Price",
    value: "price-low",
    description: "Products sorted by the lowest price first",
  },
  {
    name: "Highest Price",
    value: "price-high",
    description: "Products sorted by the highest price first",
  },
];

export default {
  components: {
    Listbox,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
    CheckIcon,
    ChevronDownIcon,
  },
  props: {
    options: {
      type: Array,
      default: () => defaultOptions,
    },
  },
  emits: ["onSort"],
  setup(props, { emit }) {
    const selectedOption = ref({});
    const optionsList = ref([]);

    onMounted(() => {
      optionsList.value = props.options;
      selectedOption.value = optionsList.value[0];
    });

    watch(selectedOption, (current) => {
      if (current.value) {
        emit("onSort", current.value);
      }
    });
    const { isLoggedIn } = Brands();
    return {
      selectedOption,
      optionsList,
      isLoggedIn,
    };
  },
};
</script>

<style lang="scss" scoped>
.filter-listing {
  @apply relative inline-flex flex-shrink-0;
}
.filter-listing-container {
  @apply absolute top-full right-0 mt-1 overflow-auto bg-white rounded shadow-lg max-h-60 focus:outline-none text-sm z-50 divide-y divide-gray-100;
  min-width: 200px;

  li {
    @apply flex flex-col select-none relative py-3 pr-10 pl-4 text-brand-primary cursor-pointer;
    &.active {
      @apply text-brand-primary bg-brand-smoke;
    }
    p {
      @apply text-sm;
    }
    span {
      @apply text-xs  text-theme-body font-normal;
      &.check-mark {
        @apply absolute inset-y-0 right-0 flex items-start pt-3 justify-center pr-3 text-brand;
        svg {
          @apply w-5 h-5;
        }
      }
    }
  }
}
.filter-listing-btn {
  @apply relative py-3 pl-4 pr-8 text-left font-normal bg-white cursor-pointer focus:outline-none text-sm ring-1 ring-white ring-opacity-20;
  min-width: 200px;

  &.disabled {
    @apply cursor-default;
  }
}
</style>
