// "use strict";
import axios from "axios";

axios.defaults.baseURL = 'https://lpfz1722w7-1.algolia.net/1/indexes';

let config = {
    headers: {
        'X-Algolia-API-Key': '3c406a920e55810962d8b823b819fd9d',
        'X-Algolia-Application-Id': 'LPFZ1722W7'
    }
};

const api = axios.create(config);

api.interceptors.request.use(
    function (config) {
        // Do something before request is sent
        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);
// Add a response interceptor
api.interceptors.response.use(
    function (response) {
        // Do something with response data
        return response;
    },
    function (error) {
        // Do something with response error
        return Promise.reject(error);
    }
);


export default {
    api
}