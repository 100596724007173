<template>
  <div class="blurry-wrap">
    <slot />
  </div>
</template>

<script>
export default {
  name: "BlurryWrap",
  props: {
    title: String,
  },
};
</script>
<style lang="scss" scoped>
.blurry-wrap {
  @apply absolute inset-1 z-20 backdrop-blur-md backdrop-filter;
}
</style>
