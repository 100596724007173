export const filterOptions = {
  state: () => ({
    filterOptions: [],
    brandFilter: [],
    selected: [],
    priceRange: 0,
    sortValue: "latest",
    minPrice: 1,
    maxPrice: 0,
    priceCheck: true,
  }),
  getters: {
    getFilters: (state) => state.filterOptions,
    getSelected: (state) => state.selected,
    getBrandFilter: (state) => state.brandFilter,
    getPriceRange: (state) => state.priceRange,
    getSortValue: (state) => state.sortValue,
    getMinPrice: (state) => state.minPrice,
    getMaxPrice: (state) => state.maxPrice,
    getPriceCheck: (state) => state.priceCheck,
  },
  mutations: {
    SET_FILTER_OPTIONS(state, filterOptions) {
      state.filterOptions = filterOptions;
    },
    SET_BRAND_FILTER_OPTIONS(state, filterOptions) {
      state.brandFilter = filterOptions;
    },
    SET_SELECTED(state, opt) {
      state.selected.push(opt);
    },
    SET_SELECTED_REMOVE(state, opt) {
      state.selected = opt;
    },
    SET_PRICE_RANGE(state, opt) {
      state.priceRange = opt;
    },
    SET_SORT_VALUE(state, opt) {
      state.sortValue = opt;
    },
    SET_MIN_PRICE(state, price) {
      state.minPrice = Math.floor(price);
    },
    SET_MAX_PRICE(state, price) {
      state.maxPrice = Math.floor(price);
    },
    SET_PRICE_CHECK(state, value) {
      state.priceCheck = value;
    },
  },

  actions: {
    updateOptions({ commit }, options) {
      const lower = (a) => a.toLocaleLowerCase();
      commit(
        "SET_FILTER_OPTIONS",
        options.sort((a, b) =>
          lower(a.name) > lower(b.name)
            ? 1
            : lower(b.name) > lower(a.name)
            ? -1
            : 0
        )
      );
    },
    updateBrandFilter({ commit }, options) {
      const lower = (a) => a.toLocaleLowerCase();
      commit(
        "SET_BRAND_FILTER_OPTIONS",
        options.sort((a, b) =>
          lower(a.name) > lower(b.name)
            ? 1
            : lower(b.name) > lower(a.name)
            ? -1
            : 0
        )
      );
    },
    selectedOption({ state, commit }, opt) {
      commit("SET_SELECTED", opt);
      // var arr [{state.select}]
    },
    removeOption({ state, commit }, opt) {
      if (opt == "clear") {
        state.selected.forEach((el) => {
          let ch = document.getElementById(`${el.slug}-${el.id}`);
          ch ? (ch.checked = false) : "";
        });
        commit("SET_SELECTED_REMOVE", []);
      } else {
        let arr = state.selected.filter((r) => r.id != opt.id);
        document.getElementById(`${opt.slug}-${opt.id}`).checked = false;
        commit("SET_SELECTED_REMOVE", arr);
      }
    },
    setPriceRange({ state, commit }, opt) {
      commit("SET_PRICE_RANGE", opt);
    },
    setSortValue({ state, commit }, opt) {
      commit("SET_SORT_VALUE", opt);
    },
    setMinPrice({ commit }, price) {
      commit("SET_MIN_PRICE", price);
    },
    setMaxPrice({ commit }, price) {
      commit("SET_MAX_PRICE", price);
    },
    setPriceCheck({ commit }, price) {
      commit("SET_PRICE_CHECK", price);
    },
  },
};
