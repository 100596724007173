<template>
  <TransitionRoot as="template" :show="isVisible">
    <div
      class="fixed bottom inset-y-0 flex items-start justify-center pt-8 sm:pt-20 px-4 text-center bg-white right-0  w-full max-w-full sm:max-w-sm z-50"
    >
      <!-- <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-theme-secondary bg-opacity-40 transition-opacity" />
        </TransitionChild> -->
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        enter-to="opacity-100 translate-y-0 sm:scale-100"
        leave="ease-in duration-200"
        leave-from="opacity-100 translate-y-0 sm:scale-100"
        leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      >
        <div class="w-full relative z-50">
          <slot />
        </div>
      </TransitionChild>
    </div>
  </TransitionRoot>
</template>

<script>
import {
  // Dialog,
  // DialogOverlay,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";

export default {
  name: "PopupModal",
  components: {
    // Dialog,
    // DialogOverlay,
    TransitionChild,
    TransitionRoot,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
