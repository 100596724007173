<template>
  <TransitionRoot as="template" :show="isVisible">
    <Dialog
        class="fixed bottom inset-0 flex items-center justify-center pt-20 px-4 text-center z-50"
    >
      <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
      >
        <DialogOverlay
            class="fixed inset-0 bg-theme-secondary bg-opacity-40 transition-opacity"
        />
      </TransitionChild>
      <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      >
        <div
            class="relative flex flex-col bg-white z-50  rounded-lg overflow-hidden"
            :style="'width:' + width +'rem'"
        >
          <div
              class="flex items-center border-b border-gray-100 px-5 py-2 space-x-1.5"
          >
            <Icon class="text-theme-dark opacity-70 w-5 h-5" :name="icon" />
            <h3 class="text-base font-semibold text-theme-dark opacity-70">
              {{ title }}
            </h3>
          </div>
          <div
              class="w-full p-5 text-left space-y-8 text-theme-body text-base font-normal leading-7"
          >
            <div class="space-y-5">
              <slot />
            </div>
            <div class="grid gap-5" :class="isConfirm ? 'grid-cols-2' : 'grid-cols-1'">
              <div @click="$emit('cancel')">
                <Button :title="cancelButton" class="medium uppercase" :class="isConfirm ? 'no-bg' : ''" />
              </div>
              <div v-if="isConfirm" @click="$emit('confirm')">
                <Button :title="confirmButton" class="medium uppercase" />
              </div>
            </div>
          </div>
        </div>
      </TransitionChild>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {
  Dialog,
  DialogOverlay,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";

export default {
  name: "Confirm",
  components: {
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
  },
  props: {
    width: {
      type: Number,
      default: 24,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "info",
    },
    title: {
      type: String,
      default: "Notice",
    },
    cancelButton: {
      type: [String, Boolean],
      default: "Go Back",
    },
    confirmButton: {
      type: [String, Boolean],
      default: "Yes",
    },
    isConfirm: {
      type: Boolean,
      default: true,
    }
  },
};
</script>
