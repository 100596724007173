<template>
  <router-view />
</template>
<style lang="scss">
#app {
  @apply font-default;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply overflow-x-hidden;
}
@media print{
    #app {overflow:visible};
}
* {
  @apply font-default;
}
[data-tippy-root] {
  z-index: 99999999999 !important;
}
</style>
