export const banner = {
    state: () => ({
        product_count: 0,
        summary: {},
        addresses: [{ country: "", state: "", city: "" }],
        selectedAddress: { country: "", state: "", city: "", state_code: '' },
        selectedState: { country: "", state: "", city: "", state_code: '' }
    }),

    getters: {
        getProductCount: (state) => state.product_count ?? 0,
        getAddresses: (state) => state.addresses,
        getSelectedAddress: (state) => state.selectedAddress,
        getSelectedState: (state) => state.selectedState,
        coins: (state) => state.summary
    },

    actions: {
        productCountAction({ commit }, count) {
            commit('SET_PRODUCT_COUNT', count);
        },
        setAddresses({ commit }, ad) {
            commit('SET_ADDRESSES', ad);
        },
        setSelectedAddress({ commit }, ad) {
            commit('SET_SELECTED_ADDRESS', ad);
        },
        setCoins({ commit }, ads) {
            commit('SET_COINS', ads);
        },
    },

    mutations: {
        SET_COINS(state, ads) {
            state.summary = ads
        },
        SET_PRODUCT_COUNT(state, count) {
            state.product_count = count
        },
        SET_ADDRESSES(state, ad) {
            state.addresses = ad;
        },
        SET_SELECTED_ADDRESS(state, ad) {
            state.selectedAddress = ad
            state.selectedState = ad ? ad.state_data : ''
        }
    },

}