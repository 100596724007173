<template>
  <div v-if="loading" class=" flex justify-center items-center">
    <div class="animate-spin rounded-full border-b-2" :class="className"></div>
  </div>
</template>

<script>
export default {
  name: "Spinner",
  props: {
    loading: {
      type: Boolean,
    },
    className: {
      type: String,
      default: () => "h-24 w-24",
    },
  },
};
</script>

<style lang="scss" scoped>
.animate-spin {
  border-color: rgb(189, 150, 111);
}
</style>
