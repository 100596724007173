import { cart } from './cart';
import { banner } from './banner';
import { toast } from './toast';
import { product } from './products';
import { filterOptions } from './filterOptions';
import { createStore } from 'vuex';

export const store = createStore({
    modules: {
        cart,
        filterOptions,
        banner,
        toast,
        product
    }
});