// "use strict";
import axios from "axios";
const token = window.localStorage.getItem('LoggedUser') || '';
axios.defaults.baseURL = process.env.BASE_URL_API || '';

let config = {
    headers: {
        "Authorization": "Bearer uTG6VD7v95ExLp7uzgDeJaFNTbxRw5otEGZLnipTOMJv1NNR1PiyD294ZWdqeriGGXVOPSDxoy9BWYhdkfDuIS3RpqME3amLrX4Qevl4t9uHGUOsaqNQ5NfvSiq0oh11qWNfG1nzgWvAlABhjTTN0XeCpPsmO7QlJRYL3TfCPXInA9ai8g34Gu4CvCaWD8FebRAg9fUud1VU0jIzJY68OkcbZUcjADTelKXfSsdwmrBlUzTax5ez8l90OJVFaLdKP2TO9Wk7E7FRpZ115tRPIXClu4nzP6RfYeoj6GDrWuGvgSFeHpkAN9M0tS2jFKMIwYmSDzRH28tH0Of89qPvO8Dof223Z1efT3yueQViwZkETySQNXYAYEO1lyGs2njMXkV5z6sXLCdc3aokRI8eiH1r4GQzrYlhWfqMmQrC4b3cF6hMDLfBPeKWz6vBktDBiC6q9u7rX12Vllj9S69kdpjaBDJjL6vjYBEXtidpPgABHA5A0wgT"
    }
};

const api = axios.create(config);

api.interceptors.request.use(
    function (config) {
        // Do something before request is sent
        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);
// Add a response interceptor
api.interceptors.response.use(
    function (response) {
        // Do something with response data
        return response;
    },
    function (error) {
        // Do something with response error
        return Promise.reject(error);
    }
);

let config2 = {
    headers: {
        "Authorization": "Bearer " + token
    }
};

const authApi = axios.create(config2);

authApi.interceptors.request.use(
    function (config) {
        // Do something before request is sent
        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);
// Add a response interceptor
authApi.interceptors.response.use(
    function (response) {
        // Do something with response data
        return response;
    },
    function (error) {
        // Do something with response error
        return Promise.reject(error);
    }
);


export default {
    api,
    authApi
}