<template>
  <div class="card">
    <div class="card-header" v-if="title">
      <h3>{{ title || "Card Title" }}</h3>
      <div class="card-action" v-if="$slots.action">
        <slot name="action" />
      </div>
    </div>
    <div class="card-content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "Button",
  props: {
    title: String,
  },
};
</script>
<style lang="scss" scoped>
.card {
  @apply flex flex-col space-y-8 w-full bg-white rounded-md shadow-box px-8 pt-6 pb-10;
  .card-header {
    @apply flex items-center justify-between w-full;
    h3 {
      @apply text-xl text-theme-secondary font-semibold;
    }
    .card-action {
      @apply text-theme-body font-medium text-base flex-shrink-0;

      a {
        @apply text-theme-body;
      }
    }
  }
  .card-content {
    @apply h-full;
  }
}
</style>
