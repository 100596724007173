<template>
  <TransitionRoot as="template" :show="isVisible" @close="() => {}">
    <Dialog
      class="fixed bottom inset-0 flex items-center justify-center px-4 text-center z-50"
      :class="isOverApp ? 'z-maximum pt-0' : 'pt-6 md:pt-20'"
    >
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <DialogOverlay
          class="fixed inset-0 bg-theme-secondary bg-opacity-40 transition-opacity"
          :class="
            isBlurOverlay
              ? 'backdrop-filter backdrop-grayscale backdrop-blur-sm'
              : ''
          "
        />
      </TransitionChild>
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        enter-to="opacity-100 translate-y-0 sm:scale-100"
        leave="ease-in duration-200"
        leave-from="opacity-100 translate-y-0 sm:scale-100"
        leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      >
        <div
          class="relative"
          :class="
            resetContainer ? containerClass : 'bg-white w-full z-50 max-w-2xl'
          "
        >
          <slot />
        </div>
      </TransitionChild>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {
  Dialog,
  DialogOverlay,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";

export default {
  name: "PopupModalFull",
  components: {
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    resetContainer: {
      type: Boolean,
      default: false,
    },
    containerClass: {
      type: String,
      default: "",
    },
    isOverApp: {
      type: Boolean,
      default: false,
    },
    isBlurOverlay: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
