<template>
    <div class="m-badge" v-if="name" :class="`variant-${variant}`">
      <em>{{ name }}</em>
    </div>
  </template>
  
  <script>
  export default {
    name: "Badge",
    props: {
      variant: {
        type: String,
        default: "default",
      },
      name: String,
    },
  };
  </script>
  <style lang="scss" scoped>
  .m-badge {
    @apply flex font-medium items-center font-default px-1.5 py-1 leading-none rounded-full relative uppercase;
    background-color: #d9e3ff;
    color: #6b77df;
  
    &.variant {
      &-primary {
        background-color: #efe4db;
        color: #42372c;
      }
      &-secondary {
        @apply bg-theme-secondary text-white;
      }
    }
  
    em {
      @apply relative not-italic;
      bottom: -1px;
      font-size: 10px;
    }
  }
  </style>
  