<template>
  <div class="flex items-center justify-center">
    <div class="stock-indicator" v-if="tickIcon && inputValue > 0" @click="removeItem">
      <span><Icon name="added-checkmark"/></span>
      <span class="clear"><Icon name="close"/></span>
    </div>

    <div
      v-if="stock > 0 && max == 0"
      class="cursor-pointer"
      v-tippy="{ content: 'Limit Exceeded', placement: 'top' }"
    >
      <Icon name="info" class="w-5 h-5" />
    </div>
    <div v-else class="flex flex-col items-center relative">
      <div class="mpm-input" v-bind="$attrs">
        <button @click="subs" v-if="inputValue">
          <Icon class="w-3.5 h-3.5" name="prod-remove" />
        </button>
        <input
          :id="id"
          v-if="inputValue"
          :value="inputValue"
          :min="min"
          :max="max"
          type="number"
          @keyup="keypess($event.target.value)"
          spellcheck="false"
          autocomplete="off"
          @wheel="$event.target.blur()"
        />
        <button v-if="!inputValue" class="add">
          <span @click="add">Add</span>
        </button>
        <button @click="add" :disabled="inputValue == max">
          <Icon class="w-3.5 h-3.5" name="prod-add" />
        </button>
      </div>
      <transition name="slide-fade">
        <span v-if="showMaxInfo || inputValue == max" class="error-info">{{
          showMsg
        }}</span>
      </transition>
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import { useStore } from "vuex";

export default {
  name: "InputPlusMinus",
  inheritAttrs: false,
  props: {
    id: {
      type: Number || String,
      default: 0,
    },
    modelValue: {
      type: Number,
      default: 0,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 99000,
    },
    tickIcon: {
      type: Boolean,
      default: false,
    },
    stock: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    modelValue(val) {
      this.inputValue = val;
    },
  },
  setup(props, { emit }) {
    const store = useStore();

    const inputValue = ref(props.modelValue);
    const showMaxInfo = ref(false);
    const showMsg = "Max Available";

    watch(inputValue, () => {
      emit("update:modelValue", inputValue.value);
      if (inputValue.value >= props.max) {
        toggleMaxInfo();
      }
    });

    const toggleMaxInfo = () => {
      showMaxInfo.value = true;
      setTimeout(() => {
        showMaxInfo.value = false;
      }, 3000);
    };
    // const updateValue = debounce(()=>{
    //   emit('getQuantity', inputValue.value);
    // },300);
    const updateValue = (con) => {
      if (props.id == store.getters.getVarID) {
        window.stop();
      }
      emit("getQuantity", [inputValue.value, con]);
      store.dispatch("setVarID", props.id);
    };

    const add = () => {
      if (inputValue.value < props.max) {
        inputValue.value++;
        updateValue(true);
      }
    };

    const subs = (qty) => {
      if (inputValue.value > props.min) {
        inputValue.value--;
        updateValue(false);
      }
    };

    const keypess = (val) => {
      inputValue.value = val ? parseInt(val) : 0;
      if (val > props.max) {
        inputValue.value = props.max;
        toggleMaxInfo();
      }
      if (val < props.min) {
        inputValue.value = props.min;
      }
      updateValue(inputValue);
    };

    const removeItem = () => {
      inputValue.value = 0;
      emit("removeItem")
    }

    return {
      showMsg,
      inputValue,
      showMaxInfo,
      add,
      subs,
      keypess,
      removeItem
    };
  },
};
</script>
<style lang="scss" scoped>
.error-info {
  @apply hidden xl:block absolute inset-x-0 bottom-0.5 transform translate-y-full text-center leading-none rounded-b-md p-1 whitespace-nowrap text-xxs text-brand-primary bg-brand-ash bg-opacity-50;
  font-size: 11px;

  &.slide-fade-enter-active {
    transition: all 0.3s ease-out;
  }

  &.slide-fade-leave-active {
    transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
  }

  &.slide-fade-enter-from,
  &.slide-fade-leave-to {
    @apply bottom-full opacity-0;
  }
}

.mpm-input {
  @apply flex justify-between bg-white rounded space-x-2 items-center px-1 py-1 border border-brand-ash border-opacity-50 hover:border-brand-ash z-10 relative;
  min-width: 74px;

  &.medium {
    @apply px-2 py-1.5 text-base;
  }

  input {
    @apply bg-transparent text-xs appearance-none block leading-none rounded text-brand-secondary focus:outline-none border-none focus:ring-0 w-12 text-center p-0;

    @include inputPlaceholder() {
      @apply text-sm text-brand-secondary leading-snug;
    }
    @include resetAutoComplete() {
      @apply text-sm text-brand-secondary leading-snug;
    }

    &:disabled {
      @apply bg-brand-smoke bg-opacity-5 cursor-default;
      &:hover {
        @apply border-brand-smoke;
      }
    }
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }

  button {
    @apply inline-block font-light text-lg text-brand-body w-3 focus:outline-none cursor-pointer hover:text-brand-secondary uppercase leading-none;

    &.add {
      @apply text-xs px-2 font-medium;

      span {
        @apply absolute h-full inset-0 flex items-center pl-3;
      }
    }

    &:disabled {
      @apply opacity-30 cursor-default hover:opacity-30;
    }
  }
}

.stock-indicator {
  @apply mx-3 relative w-5 h-5 group cursor-pointer;

  > span {
    @apply transition-opacity duration-300 inline-flex rounded-full items-center justify-center w-full h-full;

    &.clear {
      @apply opacity-0  absolute inset-0 bg-theme-red bg-opacity-10 text-theme-red;
      svg {
        @apply w-3.5 h-3.5;
      }
    }

    svg {
      @apply w-full h-full;
    }
  }

  &:hover {
    > span {
      @apply opacity-0;

      &.clear {
        @apply opacity-100;
      }
    }
  }
}
</style>

<style scoped>
::-webkit-input-placeholder {
  text-transform: initial;
}

:-moz-placeholder {
  text-transform: initial;
}

::-moz-placeholder {
  text-transform: initial;
}

:-ms-input-placeholder {
  text-transform: initial;
}
</style>
