<template>
	<div v-if="getSelected.length>0">
		<span v-for="fil in getSelected" :key="fil.id" class="filterBadge" >
			<span>{{fil.name}}</span>
			<button type="button" class="removeBtn" @click="removeBadge(fil)" >
				<svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8" >
					<path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
				</svg>
			</button>
		</span>
		<button class="clearBtn" @click="removeBadge('clear')"> clear </button>
	</div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	name: "FilterBadge",
	computed:{
		...mapGetters(['getSelected'])
	},
	beforeUnmount() {
		this.$store.dispatch("updateOptions", []);
		this.$store.dispatch("updateBrandFilter", []);
		this.$store.dispatch("setPriceRange", 0);
		this.$store.dispatch("setMaxPrice", 0);
		this.$store.dispatch("setPriceCheck", false);
		this.removeBadge("clear");
  },
	methods:{
		removeBadge(row){
			this.$store.dispatch("setPriceRange", 0);
			this.$store.dispatch("setMaxPrice", 0);
			this.$store.dispatch("removeOption",row);
		},

	}
}
</script>
<style lang="scss" scoped>
.filterBadge{
	@apply m-1 inline-flex rounded-lg border border-brand-body border-opacity-60 items-center py-1.5 pl-3 pr-2 text-base font-medium bg-transparent text-brand-body;

	.removeBtn{
		@apply flex-shrink-0 ml-1 h-4 w-4 p-1 rounded-full inline-flex text-gray-400 hover:bg-gray-200 hover:text-gray-500;
	}
}
.clearBtn{
	@apply ml-2 text-base font-semibold text-brand-secondary uppercase;
}
</style>