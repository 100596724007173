export const toast = {
    state: () => ({
        status: false,
        msg: "",
        type:'success',
    }),

    getters: {
        getToastStatus: (state) => state.status,
        getToastMsg: (state) => state.msg,
        getToastType: (state) => state.type,
    },

    actions: {
        setToast({ commit }, data) {
            if(!data.type) data.type = "success"
            commit('SET_TOAST', data);
        },
        resetToast({ commit }) {
            let data ={
                status: false,
                msg: "",
                type:'success',
            }
            commit('RESET_TOAST', data);
        },
    },
    
    mutations: {
        SET_TOAST(state, toast) {
            state.status = toast.status
            state.msg = toast.msg
            state.type = toast.type
        },
        RESET_TOAST(state, toast) {
            state.status = toast.status
            // state.msg = toast.msg
            // state.type = toast.type
        },
    },
    
}