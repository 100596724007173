<template>
  <div class="tooltip-box">
    <slot />
    <div class="tooltip">
      <slot name="text"/>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.tooltip-box {
  @apply relative inline-block;

  &:hover .tooltip {
    @apply opacity-100 pointer-events-auto;
  }

  .tooltip {
    @apply absolute text-xxs text-white text-left px-3 py-1.5 rounded-lg bg-black bg-opacity-90 bottom-full mb-1 left-1/2 transition-opacity opacity-0 duration-500 w-full pointer-events-none;
    width: 200px;
    margin-left: -100px;
    z-index: 1;

    &::after {
      @apply absolute top-full left-1/2 -ml-1 border-4;
      border-style: solid;
      border-color: rgba(0, 0, 0, 0.9) transparent transparent transparent;
    }
  }
}


</style>
